<template>
  <site-section section-class="news-states-inner">
    <template v-slot:description>
      <div class="inner-news-tovar">
        <cover :item="article" />
      </div>
      <div class="states-inner-content">
<!--         <div class="date-active-from">
          <div class="tovar-items-status">
            <span class="items-status-date">
              <i class="far fa-calendar"></i>
            </span>
            <span class="items-status-text">
              {{ article.createdDate | moment("calendar") }}
            </span>
          </div>
        </div> -->
        <h2>{{ article.title }}</h2>
        <rating-item
          :item-id="articleId"
          check-link="/article/rating/check"
          delete-link="/article/rating/delete"
          set-link="/article/rating/add"
          param-name="articleId"
          :rating="article.rating"
          :update-item="updateArticle"
        />
        <div class="states-content" v-html="article.content"></div>
        <div>
          <p>
            <br />
            Источник:
            <a
              style="color:#fff"
              :href="article.sourceUrl"
              class="source-link"
              target="_blank"
            >
              {{ article.sourceUrl }}
            </a>
          </p>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <h4 class="label-soc">Поделиться статьей</h4>
      <social-menu
        :description="article.description"
        :categories="article.categories"
        :social-style="styleSocial"
      />
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import SocialMenu from "@/components/page/menu/SocialMenu";
import Cover from "@/components/page/items/cover/Cover";
import RatingItem from "@/components/page/rating/RatingItem";
export default {
  name: "ArticleContent",
  components: { RatingItem, Cover, SocialMenu, SiteSection },
  props: {
    article: Object,
    updateArticle: Function
  },
  data() {
    return {
      styleSocial: {
        blockClass: "states-inner-social"
      }
    };
  },
  computed: {
    articleId() {
      return this.$route.params.id ? parseInt(this.$route.params.id) : 0;
    }
  }
};
</script>

<style scoped></style>
