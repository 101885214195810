<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <article-content
        :update-article="getArticle"
        :article="article"
        v-if="article"
      />
      <articles-block :media="articles" />
      <popular-block :media="popularVideos" head="Смотрите также" />
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import ArticlesBlock from "@/components/page/articles/ArticlesBlock";
import PopularBlock from "@/components/page/main/PopularBlock";
import ArticleContent from "@/components/page/articles/ArticleContent";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Article",
  components: { ArticleContent, PopularBlock, ArticlesBlock },
  data() {
    return {
      article: {}
    };
  },
  computed: {
    ...mapGetters(["popularVideos", "articles"])
  },
  methods: {
    ...mapActions(["getPopularVideos", "getArticles"]),
    getArticle(id) {
      return this.axios
        .get("/article/get", {
          params: { id: id || this.$route.params.id }
        })
        .then(response => {
          this.article = response.data;
        });
    },
    getPopularArticles() {
      this.getArticles({
        page: 1,
        size: 16
      });
    }
  },
  created() {
    this.getArticle().then(() => {
      this.getPopularVideos({
        page: 1,
        size: 16
      });
      this.getPopularArticles();
    });
  }
};
</script>

<style scoped></style>
