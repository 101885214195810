<template>
  <site-section section-class="news-states">
    <template v-slot:header>
      <h2>Читайте также</h2>
    </template>
    <template v-slot:content>
      <articles :tiles="media" :slick-style="slickStyle" v-if="media.length" />
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import Articles from "@/components/page/slick/Articles";
export default {
  name: "ArticlesBlock",
  components: { Articles, SiteSection },
  data() {
    return {
      slickStyle: {
        itemClass: "news-states-items",
        slickClass: "slider-news-spikers",
        imgClass: "spiker-items-img"
      }
    };
  },
  props: {
    media: Array
  }
};
</script>

<style scoped></style>
